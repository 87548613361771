// @ts-ignore
export function typewriter(node, { delay = 0, duration = 300 }) {
	const valid = node.childNodes.length === 1 && node.childNodes[0].nodeType === Node.TEXT_NODE;

	if (!valid) {
		throw new Error(`This transition only works on elements with a single text node child`);
	}

	const text = node.textContent;
	// const duration = text?.length / (speed * 0.01);

	return {
		duration,
		delay,
		tick: (t: number) => {
			const i = Math.ceil(text?.length * t);
			node.textContent = text?.slice(0, i);
		},
	};
}

export function typewriterCss(node: HTMLElement, { delay = 0, duration = 16 }) {
	const valid = node.childNodes.length === 1 && node.childNodes[0].nodeType === Node.TEXT_NODE;

	if (!valid) {
		throw new Error(`This transition only works on elements with a single text node child`);
	}

	const letters = (node.textContent as string).split('');
	const letterFrameTime = 16; // in ms;

	node.removeChild(node.firstChild as ChildNode);
	// const duration = text?.length / (speed * 0.01);

	node.append(
		...letters.map((letter, index) => {
			let letterSpan = document.createElement('span');
			letterSpan.innerText = letter;
			letterSpan.classList.add(
				'inline-block',
				'opacity-0',
				'whitespace-pre',
				'animation-typewritertext'
				// 'transition-[opacity,transform]',
				// `duration-[${duration}]`
			);
			letterSpan.setAttribute('style', `animation-delay: ${letterFrameTime * index}ms`);
			return letterSpan;
		})
	);

	return {
		duration,
		delay,
		css: () => {},
	};
}


