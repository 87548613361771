<script>
	export let icon, title1, title2;
</script>

<div class="ride-benefit-card text-center">
	<div
		class="bg-gradient-to-b from-[#DED6C0] from-10% via-[#D5CCB5] via-50% to-[#EEE5CE] to-100% rounded-full mb-4 md:mb-2 bg-red-500 w-fit mx-auto"
	>
		<img class="p-[7px] object-scale-down" width="50" height="50" src={icon.src} alt={title1} />
	</div>

	<p class="text-sm md:text-base text-primary">{title1}</p>
	<p class="text-sm md:text-base text-primary">{title2}</p>
</div>
