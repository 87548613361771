<script>
	import RouteSection from './RouteSection/RouteSection.svelte';
	import RouteSelectionForm from '../RouteSelectionForm.svelte';
	import ChevronRightIcon from '~/icons/Routes/chevron-right.svelte';
	import { fade } from 'svelte/transition';
	import { IndexMeta } from '~/utils/config';
	import {
		busCountOnSelectedRoute,
		dropOffFieldData,
		loading,
		formInFocus,
		pickUpFieldData,
		routeStops,
		isEditing,
		activeCity,
	} from '../store';
	import { getCheckRouteRecommendedStops } from '~/utils/api';
	import { typewriterCss } from '~/utils/animations';
	import SelectedRouteView from '../SelectedRouteView.svelte';
	import { routePrices, isBusSearch } from '../../store.ts';
	import { isMobile } from '~/utils/store';
	import DownloadAppButton from '~/components/core/DownloadAppButton.svelte';
	import { captureException } from '@sentry/astro';

	const getRouteStops = async () => {
		if ($loading) return;

		try {
			$loading = true;

			$routeStops = null;
			$busCountOnSelectedRoute = 0;

			let requestData = {};

			if ($pickUpFieldData.placesDetails) {
				requestData.pickupPlaceDetails = $pickUpFieldData.placesDetails;
			} else {
				requestData.pickup_value = $pickUpFieldData.value;
			}

			if ($dropOffFieldData.placesDetails) {
				requestData.dropOffPlaceDetails = $dropOffFieldData.placesDetails;
			} else {
				requestData.dropoff_value = $dropOffFieldData.value;
			}

			const res = await getCheckRouteRecommendedStops(requestData);
			routePrices.setSingleRidePrice(res.ony_way_pricing);
			routePrices.setFlexiPassPrice(res.flexi_pass_pricing);
			routeStops.set(res.route_data);
			formInFocus.set(false);

			busCountOnSelectedRoute.set(res.route_data.length ? res.route_data[0].timings.length : 0);
		} catch (error) {
			captureException(error);
		} finally {
			$loading = false;
		}
	};

	async function handleFindMyBus(event) {
		isEditing.set(false);
		await getRouteStops();
	}

	$: routeMapPageLink = `/routes/${$activeCity === 1 ? 'mumbai' : 'hyderabad'}/`;
</script>

<div>
	{#if $isMobile}
		<div class=" mb-6">
			{#if $isEditing}
				<RouteSelectionForm on:submit={handleFindMyBus} />
			{:else}
				<SelectedRouteView on:swap={handleFindMyBus} />
			{/if}
		</div>
	{/if}

	<!-- <RouteSelectionForm on:submit={()=>{}} ></RouteSelectionForm> -->
	<div class="flex items-center justify-between mb-4">
		<div class="flex flex-col gap-y-2">
			{#if $loading && $busCountOnSelectedRoute > 0}
				<h3 class="text-xl md:text-3xl leading-7 text-center md:text-left font-bold sm:pt-2 text-primary">
					{`${$busCountOnSelectedRoute} buses available`}
				</h3>
			{:else if $busCountOnSelectedRoute && $routeStops?.length && !$formInFocus}
				<h3
					class="text-xl md:text-3xl leading-7 text-center md:text-left font-bold sm:pt-2 text-primary"
					in:typewriterCss={{ duration: 500 }}
				>
					{`${$busCountOnSelectedRoute} buses available`}
				</h3>
			{:else if !$loading && !$busCountOnSelectedRoute && $routeStops?.length == 0}
				<h3
					class="text-xl md:text-3xl leading-7 text-center md:text-left font-bold sm:pt-2 text-primary"
					in:typewriterCss={{ duration: 500 }}
				>
					No buses available
				</h3>
			{:else if !$isMobile}
				<h3 class="text-2xl leading-7 text-center md:text-left font-bold sm:pt-4 text-primary" in:typewriterCss>
					Find Your Bus
				</h3>
			{/if}
			{#if !$isMobile}
				<p class="text-base">First ride <span class="text-[#9C7000]">Free</span></p>
			{/if}
		</div>
		{#if $routeStops?.length}
			<div class="flex items-center justify-between">
				<a class="text-center text-primary font-medium" href={routeMapPageLink}>View full route</a>

				<ChevronRightIcon class=" w-6 h-6" />
			</div>
		{/if}
	</div>

	<RouteSection />
	{#if !$isMobile && $isBusSearch}
		{#if $loading}
			<div in:fade={{ delay: 150 }} class="grid grid-rows-[auto_1fr_auto] gap-4 h-full">
				<div class="flex flex-row gap-2">
					<div class="bg-gray-200 rounded-xl w-[50%] h-5" />
					<div class="bg-gray-200 rounded-xl w-[50%] h-5" />
				</div>
			</div>
		{:else}
			<div class="flex items-center mt-4">
				<p class="text-sm">
					One-way single rides <span class="text-[#9C7000]"
						>starting at ₹{$routePrices.single_ride_price}</span
					>
				</p>
				&nbsp; | &nbsp;
				<p class="text-sm">
					Weekly/ monthly <span class="text-[#9C7000]"
						>flexi plans starting at ₹{$routePrices.flexi_pass_price}/ride.</span
					>
				</p>
			</div>
		{/if}
	{/if}

	{#if $isBusSearch && !$isMobile}
		<div class="flex justify-between items-center mt-4">
			<div class="md:w-fit">
				<DownloadAppButton
					id="download-the-app-search"
					className="bg-white text-primary h-16 border-none"
					icons
					rating
					iconColor="#00253F"
					downloadAppLink={IndexMeta.downloadAppLink}
				/>
			</div>
			{#if $routeStops?.length}
				<a class="text-center underline underline-offset-2 text-primary font-bold md:pr-7" href={routeMapPageLink}
					>View all available routes</a
				>
			{/if}
		</div>
	{/if}
	<!-- <div>
		<p>Download Button here</p>
		<a
			id={`available-routes-${activeCityId === 1 ? 'mumbai' : 'hyderabad'}-find-your-bus`}
			class="btn btn-primary-hollow sm:max-w-[45%] mx-8 md:ml-0 md:mr-0 nav-in"
			href={routeMapPageLink}>View all available routes</a
		>
	</div> -->
</div>

<style>
</style>
