<script>
	export let times;
	const [pickTime, dropTime] = times.split('-').map((time) => time.trim().toLowerCase());

</script>

<div class="grid gap-1 pointer-events-none justify-center bg-[#FBFBFB] border border-[#E2E2E2] rounded-2xl px-2 py-2">
	<div class="grid grid-cols-[0.5rem_1fr] gap-x-[6px]">
		<div class="grid grid-rows-[0.5rem_1fr] justify-center translate-y-[6px]">
			<span class="block aspect-square w-[0.313rem] bg-accent rounded-full" />
			<span class="h-full w-0 border-l-[1px] border-neutral-100 border-dashed mx-auto" />
		</div>
		<div class="text-[#3B3839]">
			<p class="text-xs font-medium">
				{pickTime}
			</p>
		</div>
	</div>
	<div class="grid grid-cols-[0.5rem_1fr] gap-x-[6px]">
		<div class="grid grid-rows-[0.2rem_0rem] justify-center translate-y-[2px]">
			<span class="h-full w-0 border-l-[1px] border-neutral-100 border-dashed mx-auto" />
			<span class="aspect-square w-[0.313rem] bg-primary rounded-full" />
		</div>
		<div class="text-[#3B3839]">
			<p class="text-xs font-medium">{dropTime}</p>
		</div>
	</div>
</div>
