<script>
	import { nanoid } from 'nanoid';
	import { debounce } from '~/utils/debounce';
	import { getCheckRouteSearchSuggestion, getPlaceDetails } from '~/utils/api';
	import { scale, slide } from 'svelte/transition';
	import { quintIn } from 'svelte/easing';
	import { captureException } from '@sentry/astro';

	import { formInFocus, dropOffFieldData, pickUpFieldData, loading } from './store';
	import { isBusSearch } from '../store';

	export let name,
		placeholder,
		initialLabel,
		label,
		type,
		labelStyleClass,
		inputStyleClass,
		placeHolderStyleClass,
		errorMessage,
		tabindex;

	$: fieldData = name === 'pickup' ? pickUpFieldData : dropOffFieldData;

	let inputRef;
	let selectBoxBottomOverFlow = false;
	let selectBoxRef;
	let showSelectBox = false;

	// Show label according to user behaviour
	$: labelContent = initialLabel;
	let onInput = () => {};

	const getAndSetLocations = debounce(async (value) => {
		try {
			const res = await getCheckRouteSearchSuggestion({
				search_term: value,
				session_token: $fieldData.sessionToken,
			});

			$fieldData.selectBoxOptions = res.places;
			
			showSelectBox = true;
		} catch (error) {
			captureException(error);
		}
	});

	// call when user selected any place from search sugession
	const getAndSetSelectedPlaceDetails = async (place_id) => {
		try {
			const placeDetails = await getPlaceDetails({
				place_id,
				session_token: $fieldData.sessionToken,
			});

			$fieldData.placesDetails = placeDetails;
		} catch (error) {}
	};

	function onBlur(e) {
		if (!$fieldData.value) labelContent = initialLabel;

		// if (!$location) inputValue = '';
		showSelectBox = false;
	}

	function onFocus(e) {
		labelContent = label;
		showSelectBox = true;
	}

	$: if (selectBoxRef?.getClientRects) {
		onInput = (e) => {
			const { value } = e.target;

			try {
				const { top } = selectBoxRef.getClientRects()[0];
				if (280 + top > window.innerHeight) selectBoxBottomOverFlow = true;
				else selectBoxBottomOverFlow = false;
			} catch(error) {
				selectBoxBottomOverFlow = false;
			}

			$fieldData.value = value;
			$fieldData.placesDetails = null;
			$fieldData.location = null;

			$formInFocus = true;

			if (value) {
				getAndSetLocations(value);
			} else {
				$fieldData.selectBoxOptions = [];
			}
		};
	}

	function onLocationClick(detail) {
		$fieldData.value = detail.main_text;

		$fieldData.sessionToken = nanoid();

		$fieldData.location = detail;

		showSelectBox = false;

		getAndSetSelectedPlaceDetails(detail.place_id, true);
	}

	$: value = $fieldData.location?.main_text || $fieldData.value;

	$: if (inputRef) {
		if (value) inputRef.setCustomValidity('');
		else inputRef.setCustomValidity(errorMessage);
	}
	$: if ($isBusSearch) showSelectBox = false;
	// hack to discard default dailog behaviour (https://github.com/whatwg/html/wiki/dialog--initial-focus,-a-proposal)
	$: if ($isBusSearch) inputRef?.blur();
</script>

<div class="relative">
	<input
		autocomplete="off"
		bind:this={inputRef}
		class={`click peer h:[60px] md:h-[85px] border-none md:text-lg w-full rounded-full bg-[#FAFAF0] px-8 md:px-14 placeholder:text-transparent search ${placeHolderStyleClass} ${inputStyleClass}`}
		data-ga4-event={`location-search-${name}`}
		data-ga4-param-place-value={value}
		disabled={$loading}
		on:blur={onBlur}
		on:input={onInput}
		on:focus={onFocus}
		on:click={onFocus}
		{name}
		{placeholder}
		{tabindex}
		{type}
		{value}
	/>
	<label
		class={`text-[#6B8190] text-base md:text-[18px] px-8 md:px-14 -translate-y-[2.7rem] md:-translate-y-14 duration-150 ease-out md:peer-focus:-translate-y-16 peer-focus:-translate-y-[3.6rem] peer-focus:text-[0.8rem] peer-focus:text-accent peer-[:not(:placeholder-shown)]:-translate-y-[3.6rem] md:peer-[:not(:placeholder-shown)]:-translate-y-16 peer-[:not(:placeholder-shown)]:text-[0.8rem] peer-[:not(:placeholder-shown)]:text-accent ${labelStyleClass}`}
		for={name}
	>
		{labelContent}
	</label>
	<ul
		bind:this={selectBoxRef}
		class={`${
			selectBoxBottomOverFlow ? ' bottom-full' : 'top-full'
		} absolute w-full max-h-96 h-fit bg-neutral-25 mt-1 z-20 rounded-lg overflow-y-auto shadow-2xl hide-scroll-bar`}
		class:hidden={!showSelectBox}
		in:scale={{ delay: -14600, duration: 15000, opacity: 0, start: 0.95, easing: quintIn }}
	>
		{#each $fieldData.selectBoxOptions.slice(0, 5) as option (option.place_id)}
			<li
				id={`${name}-select`}
				class="hover:bg-gray-200 border-x-2 last:border-b-0 p-2 md:py-4 cursor-pointer click"
				class:border-accent={option.place_id === $fieldData.location?.place_id}
				class:bg-accent-50={option.place_id === $fieldData.location?.place_id}
				on:mousedown|preventDefault={() => onLocationClick(option)}
				transition:slide|local={{ delay: 150, duration: 300, easing: quintIn, axis: 'x' }}
			>
				<!-- transition:slide|local -->
				<!-- in:receive={{ key: option.id }} -->
				<!-- out:send={{ key: option.id }} -->
				<!-- animate:flip -->
				<p class="font-medium">{option.main_text}</p>
				<p class="text-xs text-neutral-300 font-normal text-ellipsis whitespace-nowrap">{option.description}</p>
			</li>
		{/each}
	</ul>
	<!-- {/if} -->
</div>

<style>
	input {
		box-shadow:
			0px 2px 4px 0px rgba(0, 37, 63, 0.08),
			0px 0px 1px 0px rgba(0, 37, 63, 0.32);
	}
	input:focus {
		border: none;
		box-shadow: none;
	}
</style>
