<script>
	import { loading, routeStops } from '../../store';
	import { crossfade, scale, fade } from 'svelte/transition';
	import RoutesTimingSection from './RoutesTimingSection.svelte';
	import BusNotFound from '../BusNotFound.svelte';
	import { isMobile } from '~/utils/store';

	const [send, receive] = crossfade({
		duration: 200,
		fallback: scale,
	});

	export let containerClass;
</script>

<div
	id={$routeStops?.length ? 'search-results-shown-true' : 'search-results-shown-false'}
	class={`search-results -mx-6 md:mx-auto ${containerClass} ${$routeStops?.length ? 'overflow-y-auto accent-scrollbar' : ''}`}
>
	{#if $loading}
		<div in:fade={{ delay: 150 }} class="grid grid-rows-[auto_1fr_auto] h-full">
			{#if $isMobile}
				<div>
					<div class="flex gap-2 mb-2">
						<div class="bg-gray-200 rounded-xl w-[90%] h-8 ml-5" />
						<div class="bg-gray-200 rounded-xl w-[10%] h-8" />
					</div>
					<div class="flex flex-row gap-2">
						<div class="bg-gray-200 rounded-xl w-[90%] h-[21rem] ml-5" />
						<div class="bg-gray-200 rounded-xl w-[10%] h-[21rem]" />
					</div>
				</div>
			{:else}
				<div class="flex flex-row gap-2">
					<div class="bg-gray-200 rounded-xl w-[60%] h-[25rem]" />
					<div class="bg-gray-200 rounded-xl w-[40%] h-[25rem]" />
				</div>
			{/if}
		</div>
	{:else if !$loading && $routeStops?.length}
		<RoutesTimingSection />
	{:else}
		<BusNotFound />
	{/if}
</div>

<style>
	.check-route-section-heading {
		@apply text-complementary-brown text-base font-bold md:text-xl;
	}

	.check-route-section-action {
		@apply text-accent cursor-pointer text-sm font-normal underline md:text-sm;
	}
</style>
