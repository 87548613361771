<script>
	import RouteInfoView from './RouteInfoView.svelte';
	import TimeBox from './TimeBox.svelte';
	import { crossfade, scale, fade } from 'svelte/transition';

	export let routeDate;

	function shakeDownload() {
		const downloadButton = document.getElementById('download-the-app-search');
		downloadButton.classList.add('shake');
		setTimeout(() => {
			downloadButton.classList.remove('shake');
		}, 1000);
	}

</script>

<div class="relative rounded-[1rem] bg-neutral-0 ce-100 nav-in pt-8 pb-4 px-2">
	<span
		class={` text-sm absolute -top-4 left-4 bg-gradient-to-b ${routeDate.tag == 'Nearest' ? ' from-[#E6F0EC]  to-[#FFFFFF] text-[#2B8064]' : 'from-[#DBE9F2] to-[#FFFFFF] text-[#2B4A60]'} border rounded-md py-1 px-3 shadow-[0_2px_4px_0px_rgba(0,37,63,0.08)_0px_0px_1px_0px_rgba(0,37,63,0.32)]`}
		>{routeDate.tag}</span
	>
	<RouteInfoView
		startStopName={routeDate.start_stop.name}
		startStopTime={routeDate.start_stop.time_to_start_stop}
		endStopName={routeDate.end_stop.name}
		endStopTime={routeDate.end_stop.time_to_end_stop}
	/>

	<ul
		class="route-timings-section grid grid-cols-3 gap-1 mt-4 pb-4 h-[10rem] md:h-[14.5rem] overflow-y-auto auto-rows-min px-1"
	>
		{#each routeDate.timings as times}
			<li on:click={shakeDownload}>
				<TimeBox {times} />
			</li>
		{/each}
	</ul>
	<div
		id="blur-layer"
		class=" absolute bottom-0 left-0 w-full h-12 md:h-24 rotate-180 rounded-[20px_20px_0_0] bg-[linear-gradient(180deg,#FFF_8.33%,#ffffff00_100%)]"
	/>
</div>

<style>
	.route-timings-section::-webkit-scrollbar {
		width: 5px;
	}

	/* Track */
	.route-timings-section::-webkit-scrollbar-track {
		background-color: #ededed;
		border-radius: 10px;
	}

	/* Handle */
	.route-timings-section::-webkit-scrollbar-thumb {
		background: #e2aa2f;
		border-radius: 10px;
	}

	/* Handle on hover */
	.route-timings-section::-webkit-scrollbar-thumb:hover {
		background: #e2aa2f;
	}
</style>
