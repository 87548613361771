<script>
	import RouteSearchSection from '~/components/widgets/Home/CityfloCheckRoute/RouteSearchSection/RouteSearchSection.svelte';
	import RouteCards from '~/components/widgets/Home/CityfloRouteCards.svelte';
	import {
		pickUpFieldData,
		dropOffFieldData,
		routeStops,
		loading,
		formInFocus,
		activeCity,
		busCountOnSelectedRoute,
		placeHolderData,
	} from './store';
	import RouteSelectionForm from './RouteSelectionForm.svelte';
	import { getCheckRouteRecommendedStops } from '~/utils/api';
	import ReserveSeatIcon from '~/icons/reserve_seat.svg';
	import SubscriptionIcon from '~/icons/subscription_Icon.svg';
	import RideTypeIcon from '~/icons/ride_type.svg';
	import RideBenefitCard from '~/components/widgets/Home/CityfloCheckRoute/RideBenefitCard.svelte';
	// import CharminarVideoMp4 from '~/assets/animations/Charminar_800x800.mp4?url';
	// import TajVideoMp4 from '~/assets/animations/Taj_800x800.mp4?url';
	import { isDoubleShiftRoute } from '~/utils/utils';
	import { isBusSearch, routePrices } from '../store.ts';
	import { isMobile } from '~/utils/store';
	import { onMount } from 'svelte';

	let cities = [
		{
			name: 'MUMBAI',
			id: 1,
		},
		{
			name: 'HYDERABAD',
			id: 2,
		},
	];

	let rideBenefitData = [
		{
			icon: ReserveSeatIcon,
			title1: 'Reserve a seat',
			title2: 'of your choice',
		},
		{
			icon: SubscriptionIcon,
			title1: 'Reschedule your',
			title2: 'ride anytime',
		},
		{
			icon: RideTypeIcon,
			title1: 'Refund on ride',
			title2: 'cancellations',
		},
	];

	let activeCityId = 1;

	export let routesData;

	let routeData;

	let routeMapPageLink;

	onMount(() => {
		const hash = window.location.hash;
		if (hash) {
			const hashElement = document.getElementById(hash.substring(1));
			hashElement.scrollIntoView();
		}
	});

	const getRouteStops = async () => {
		if ($loading) return;

		try {
			$loading = true;

			$routeStops = null;
			$busCountOnSelectedRoute = 0;

			let requestData = {};

			if ($pickUpFieldData.placesDetails) {
				requestData.pickupPlaceDetails = $pickUpFieldData.placesDetails;
			} else {
				requestData.pickup_value = $pickUpFieldData.value;
			}

			if ($dropOffFieldData.placesDetails) {
				requestData.dropOffPlaceDetails = $dropOffFieldData.placesDetails;
			} else {
				requestData.dropoff_value = $dropOffFieldData.value;
			}
			const res = await getCheckRouteRecommendedStops(requestData);

			routeStops.set(res.route_data);
			formInFocus.set(false);
			routePrices.setSingleRidePrice(res.ony_way_pricing);
			routePrices.setFlexiPassPrice(res.flexi_pass_pricing);

			busCountOnSelectedRoute.set(res.route_data.length ? res.route_data[0].timings.length : 0);
		} catch (error) {
		} finally {
			$loading = false;
		}
	};

	function handleActiveCity(id) {
		activeCity.set(id);
		activeCityId = id;
		$pickUpFieldData.placeholder = placeHolderData[id].pickup;
		$dropOffFieldData.placeholder = placeHolderData[id].dropoff;
	}

	async function handleBusSearch() {
		isBusSearch.set(true);
		formInFocus.set(false);
		await getRouteStops();
	}

	$: routeMapPageLink = `/routes/${activeCityId === 1 ? 'mumbai' : 'hyderabad'}/`;

	$: routeData = routesData[activeCityId].filter(isDoubleShiftRoute);
</script>

<section
	id="find-my-bus"
	class:bg-[linear-gradient(to_bottom,#e7e6dd_0%,#e7e6dd_70%,#00253f_70%,#00253f_100%)]={$isBusSearch && $isMobile}
	class:pb-12={!$isBusSearch}
	class="relative md:min-h-fit bg-complementary-grey px-6 pt-16 md:pl-36 md:pr-6 md:py-24 grid grid-cols-1 md:grid-cols-2 gap-x-28"
>
	<div>
		<h2 class="tracking-normal heading text-primary mb-5">Let’s find you a bus</h2>
		<p class="content text-[18px] md:text-2xl md:mb-6 md:font-medium text-[#636061]">
			We are available in <span class="text-primary font-bold">Mumbai</span> &
			<span class="text-primary font-bold">Hyderabad</span>.
		</p>
		<div class="md:pr-0">
			<div class="ride-benefit-section flex justify-between md:gap-x-4 py-12 md:w-11/12 xl:w-3/4 2xl:w-2/3">
				{#each rideBenefitData as benefit}
					<RideBenefitCard {...benefit} />
				{/each}
			</div>
			{#if !$isMobile || (!$isBusSearch && $isMobile)}
				<RouteSelectionForm on:submit={handleBusSearch} mainForm></RouteSelectionForm>
			{/if}
		</div>
	</div>

	{#if !$isBusSearch}
		<div class="hidden md:block">
			<h3 class="text-2xl leading-7 text-center md:text-left font-bold sm:pt-4 mb-12 text-primary">
				Our Available Routes
			</h3>
			<div class="flex flex-col self-center">
				<div class="sm:border-b-2 hidden md:block border-white w-4/5">
					<div
						class="city-selection w-full sticky top-0 z-10 grid grid-cols-2 text-sm font-medium text-center overflow-hidden"
					>
						{#each cities as city (city.id)}
							<button
								id={`${city.name.toLocaleLowerCase()}-city-change-find-your-bus`}
								on:click={() => handleActiveCity(city.id)}
								class={`flex-1 cursor-pointer ease-linear p-4 text-lg click ${
									activeCityId === city.id
										? 'text-primary font-extrabold'
										: 'text-primary-800  font-medium'
								}`}
								class:list={[
									'flex-1 cursor-pointer border-b-2 border-neutral-0 ease-linear p-2 text-lg lg:text[16px] 2xl:text-[20px] click',
									activeCityId === city.id
										? 'text-primary font-extrabold'
										: 'text-primary-800 font-medium',
								]}
							>
								{city.name}
							</button>
						{/each}

						<span
							class="h-[3px] w-1/2 bg-accent absolute bottom-0 right-0 -translate-x-full"
							class:active-hyderabad={activeCityId !== 1}
							class:active-mumbai={activeCityId === 1}
						></span>
					</div>
				</div>
				<div
					class="md:hidden mb-10 city-selection grid grid-cols-2 text-sm font-medium text-center relative overflow-hidden mx-8 md:mx-12"
				>
					{#each cities as city (city.id)}
						<button
							on:click={() => handleActiveCity(city.id)}
							class={`flex-1 cursor-pointer border-b-2 border-white ease-linear p-4 text-lg click ${
								activeCityId === city.id
									? 'text-primary font-extrabold'
									: 'text-primary-800  font-medium'
							}`}
						>
							{city.name}
						</button>
					{/each}
					<span
						class="h-[2px] w-1/2 bg-accent absolute bottom-0 right-0 -translate-x-full"
						class:active-hyderabad={activeCityId !== 1}
						class:active-mumbai={activeCityId === 1}
					/>
				</div>
				<RouteCards routes={routeData} />
				<a
					id={`available-routes-${activeCityId === 1 ? 'mumbai' : 'hyderabad'}-find-your-bus`}
					class="btn btn-primary-hollow sm:max-w-[65%] mx-8 md:ml-0 md:mr-0 nav-in"
					href={routeMapPageLink}>View all available routes</a
				>
			</div>
		</div>
	{/if}

	{#if $isBusSearch || $routeStops?.length}
		<RouteSearchSection />
	{/if}
</section>
