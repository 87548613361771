<script>
	import { dropOffFieldData, pickUpFieldData, loading, isEditing } from './store';
	import SwapSVG from '~/icons/shuffle.svelte';
	import EditSVG from '~/icons/edit.svelte';
	import Edit from '~/icons/edit.svelte';
	import { createEventDispatcher, onMount } from 'svelte';

	let selectedStopsRef;

	$: pickupValue = $pickUpFieldData.value;
	$: dropoffValue = $dropOffFieldData.value;

	onMount(() => {
		setTimeout(() => {
			selectedStopsRef.scrollIntoView();
		}, 500);
	});

	let toggleSwapIconPosition = false;
	const dispatch = createEventDispatcher();
	function handleSwapLogic() {
		toggleSwapIconPosition = !toggleSwapIconPosition;

		let tempSelectedPlaceDetails = $pickUpFieldData;
		$pickUpFieldData = $dropOffFieldData;
		$dropOffFieldData = tempSelectedPlaceDetails;

		// $formInFocus = true;

		if (!!$pickUpFieldData.value && !!$dropOffFieldData.value) {
			dispatch('swap');
		}
	}

	function handleEdit() {
		$isEditing = true;
	}
</script>

<section bind:this={selectedStopsRef} class="selected-stops-section pt-10">
	<div class="filled-data-section h-12 bg-[#FAFAF0] rounded-[3rem] flex justify-between items-center px-4">
		<span class="w-[40%] text-ellipsis whitespace-nowrap overflow-hidden" on:click={handleEdit}>{pickupValue}</span>
		<button
			class={`cursor-pointer scale-75 rounded-full p-4 bg-accent transition-transform duration-150 ease-out rotate-90`}
			tabindex="-1"
			type="submit"
			class:rotate-[270deg]={toggleSwapIconPosition}
			on:click={handleSwapLogic}
		>
			<SwapSVG class="w-6 h-6 pointer-events-none" />
		</button>
		<span class="w-[40%] text-ellipsis whitespace-nowrap overflow-hidden" on:click={handleEdit}>{dropoffValue}</span>
		<button class="cursor-pointer" tabindex="-1" type="submit" on:click={handleEdit}>
			<Edit class="w-6 h-6 pointer-events-none" />
		</button>
	</div>
</section>

<style>
	.filled-data-section {
		box-shadow:
			0px 2px 4px 0px rgba(0, 37, 63, 0.08),
			0px 0px 1px 0px rgba(0, 37, 63, 0.32);
	}
</style>
