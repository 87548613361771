<script>
	import RunIcon from '~/icons/run.svelte';
	import CarIcon from '~/icons/car.svelte';
	export let startStopName;
	export let startStopTime;
	export let endStopName;
	export let endStopTime;
</script>

<div class="grid gap-4 pointer-events-none">
	<div class="grid grid-cols-[1rem_auto] gap-x-4">
		<div class="grid grid-rows-[1rem_1fr] justify-center translate-y-2">
			<span class="block aspect-square w-[0.75rem] bg-accent rounded-full" />
			<span class="h-full w-0 border-l-2 border-neutral-100 border-dashed mx-auto" />
		</div>
		<div class="text-primary">
			<p class="text-lg font-medium">
				{startStopName}
			</p>
			<div class="flex items-center gap-x-2">
				{#if startStopTime.includes('drive')}
					<CarIcon />
				{:else}
					<RunIcon />
				{/if}
				<p class="text-sm md:text-base font-normal text-[#636061]">
					{startStopTime}
				</p>
			</div>
		</div>
	</div>
	<div class="grid grid-cols-[1rem_auto] gap-x-4">
		<div class="grid grid-rows-[1fr_1rem] justify-center -translate-y-7">
			<span class="h-full w-0 border-l-2 border-neutral-100 border-dashed mx-auto" />
			<span class="aspect-square w-[0.75rem] bg-primary rounded-full" />
		</div>
		<div class="text-primary">
			<p class="text-lg font-medium">{endStopName}</p>

			<div class="flex items-center gap-x-2">
				{#if endStopTime.includes('drive')}
					<CarIcon />
				{:else}
					<RunIcon />
				{/if}
				<p class="text-sm md:text-base font-normal text-[#636061]">
					{endStopTime}
				</p>
			</div>
		</div>
	</div>
</div>
