<script>
	import { sectionIntroDelay, activeCity } from '../store';
	import RouteNotFoundImage from '~/assets/images/home/no-route-found.webp?url';
	import { IndexMeta, RouteExplorationHyderabadMeta, RouteExplorationMumbaiMeta } from '~/utils/config';
	import { fade } from 'svelte/transition';

	const aspectRatio = 916.0 / 713.0;

	$: routeMapPageLink = `/routes/${$activeCity === 1 ? 'mumbai' : 'hyderabad'}/`;
</script>

<div
	in:fade={{ delay: 250 }}
	class="text-center h-full flex flex-col justify-center bg-white py-10 px-8 md:px-2 rounded-3xl mx-8 md:mx-auto"
>
	<img
		in:fade={{ delay: sectionIntroDelay - 300 }}
		out:fade={{ delay: sectionIntroDelay - 100, duration: 150 }}
		class="h-36 mx-auto my-8"
		src={RouteNotFoundImage}
		alt=""
		style={`aspect-ratio: ${aspectRatio}`}
	/>
	<p
		in:fade={{ delay: sectionIntroDelay - 100 }}
		out:fade={{ delay: sectionIntroDelay - 300, duration: 150 }}
		class="text-sm md:text-lg font-normal mb-8 md:px-32"
	>
		Sorry! We don't have buses on your route at the moment at the moment, you can check all routes
	</p>

	<a class="text-center underline underline-offset-2 text-primary font-bold" href={routeMapPageLink}
		>View all available routes</a
	>
</div>
